
export default class Project {

    constructor() {
        this.projectId = 0;
        this.name = true;
        this.cause = true;
        this.incentiveLaw = true;
        this.odsList = true;
        this.objective = true;
        this.socialProblem = true;
        this.solution = true;
        this.methodology = true;
        this.targetAudience = true;
        this.coverage = true; 
        this.donationProduct = true;
        this.beneficiaries = true;
        this.dates = true;
        this.schedule = true;
        this.goals = true;
        this.investiment = true;
        this.bankData = true;
        this.conterpart = true;
        this.supporter = true;
    }
}