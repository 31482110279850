import File from "@/scripts/models/file.model";
import { FileType } from "@/scripts/models/enums/fileType.enum";

export default class ProjectNews {

	constructor() {
		this.id = 0;
		this.projectId = 0;
		this.fileId = 0;
		this.title = '';
		this.body = '';
		this.file = new File();
		this.fileType = FileType.ImagemNoticiaProjeto;
	}
}