<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="730">
      <template></template>
      <v-card>
        <v-card-title class="headline">
          <h1>Nova notícia</h1>
          <a href="#"></a>
          <v-btn class="close-modal" @click="close">
            <i class="fal fa-times"></i>
          </v-btn>
        </v-card-title>
        <div>
          <v-card-text style="height: 470px;">
            <v-layout row wrap pa-3 class="list-scroll">
              <v-flex xs5 pr-3>
                <div
                  class="upload-image"
                  id="preview"
                  ref="preview"
                  :style="'background-image:' + getImage()"
                >
                  <label for="upload-newsimage-project">
                    <i class="fas fa-camera-alt"></i>
                  </label>
                  <label
                    for="upload-newsimage-project"
                    class="do-upload"
                    v-show="projectNews.file.path == ''"
                  >
                    <i class="fal fa-cloud-upload"></i>Faça Upload
                  </label>
                  <input
                    type="file"
                    id="upload-newsimage-project"
                    accept="image/*"
                    @change="fileChanged"
                    ref="upload_newsimage_project"
                  />
                </div>
              </v-flex>
              <v-flex xs7>
                <InputText
                  v-model="projectNews.title"
                  type="text"
                  place-holder="Informe o headline da notícia"
                  textLabel="Título"
                />
                <hr class="md" />
                <label class="text-gray">Texto da Notícia</label>
                <vue-editor :editor-toolbar="customToolbar" v-model="projectNews.body" />
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions class="pa-0">
            <div class="footer-modal-atividades">
              <v-btn
                flat
                round
                color="white"
                class="btn btn-default px-5"
                large
                @click="close"
              >Cancelar</v-btn>
              <v-btn
                flat
                round
                color="white"
                class="btn btn-primary px-5"
                large
                @click="save"
              >Salvar</v-btn>
            </div>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script type="plain/text">
import ProjectService from "@/scripts/services/project.service";
import ProjectNews from "@/scripts/models/projectNews.model";
import ValidatableComponent from "@/components/base/ValidatableComponent.vue";
import { VueEditor } from "vue2-editor";

export default {
  components: {
    VueEditor
  },
  props: {
    projectId: 0
  },
  extends: ValidatableComponent,
  data: () => {
    return {
      dialog: false,
      creatingNewNews: false,
      projectService: new ProjectService(),
      projectNews: new ProjectNews(),
      originalProjectNews: null,
      customToolbar: [["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }]],
    };
  },
  watch: {
    dialog(v) {
      if (v && this.creatingNewNews) {
        this.create();
      }
    }
  },
  methods: {
    open(item, creatingNewNews) {
      this.dialog = true;
      this.creatingNewNews = creatingNewNews;
      this.copy(item, this.projectNews);
    },
    close() {
      this.dialog = false;
      this.copy(new ProjectNews(), this.projectNews);
      this.$refs.preview.style.backgroundImage = "";
      this.$emit("close");
    },
    create() {
      this.projectNews = new ProjectNews();
    },
    copy(from, to) {
      to.id = from.id;
      to.projectId = from.projectId;
      to.fileId = from.fileId;
      to.title = from.title;
      to.body = from.body;
      to.file.id = from.file.id;
      to.file.userId = from.file.userId;
      to.file.name = from.file.name;
      to.file.path = from.file.path;
      to.file.size = from.file.size;
      to.file.extension = from.file.extension;
      to.file.origin = from.file.origin;
      to.file.publicId = from.file.publicId;
      to.fileType = from.fileType;
      if (from.file != null && from.file.hasOwnProperty("imageContent")) {
        to.file.imageContent = from.file.imageContent;
      }
    },
    fileChanged() {
      var file = this.$refs.upload_newsimage_project.files[0];
      let reader = new FileReader();
      this.projectNews.file.name = file.name;
      reader.onload = function() {
        this.projectNews.file.path = reader.result;
        this.$set(this.projectNews.file, "imageContent", reader.result);
      }.bind(this);
      reader.readAsDataURL(file);
    },
    getImage() {
      if (
        this.projectNews == null ||
        this.projectNews.file == null ||
        !this.projectNews.file.hasOwnProperty("imageContent")
      )
        return "";
      return "url(" + this.projectNews.file.path + ")";
    },
    save() {
      this.projectNews.projectId = this.projectId;
      this.projectService.saveNews(this.saveCallback, this.projectNews);
    },
    saveCallback() {
      this.$emit("save");
      this.dialog = false;
    }
  }
};
</script>
