<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" scrollable max-width="900px">
      <v-card>
        <v-card-title class="headline">
          <h1>Notícias</h1>
          <a href="#"></a>
          <v-btn class="close-modal" @click="dialog = false">
            <i class="fal fa-times"></i>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 550px;">
          <v-layout w100 d-flex>
            <v-flex xs12 class="pt-4 border-horizontal display-flex f-d-column">
              <v-btn
                v-if="allowEditing"
                flat
                round
                color="white"
                class="d-flex ml-auto btn btn-primary p-425-0-25 px-4 mb-2"
                large
                @click="newNews"
              >
                <i class="far fa-plus mr-2"></i>Nova notícia
              </v-btn>
              <div class="list-scroll mt-4 teste">
                <v-flex d-flex box-list-activity v-for="news in project.newsList" :key="news.id">
                  <div class="mr-4 t-767-center">
                    <FileImage :file="news.file" :alt="''" />
                  </div>
                  <div class="w-100 pr-3">
                    <v-layout class="justify-space-between content-listactivity">
                      <v-flex head-listactivity>
                        <h2>{{ news.title }}</h2>
                      </v-flex>
                      <v-flex shrink no-wrap list-tools>
                        <a href="#" @click="editNews(news)" v-if="allowEditing">
                          <i class="fal fa-pen"></i>
                        </a>
                        <a
                          href="#"
                          @click="openConfirmDeleteNewsModal(news.id)"
                          v-if="allowEditing"
                        >
                          <i class="fal fa-trash"></i>
                        </a>
                      </v-flex>
                    </v-layout>
                    <p class="text-default" v-html="news.body"></p>
                  </div>
                </v-flex>
                <AucAlert
                  ref="deleteNewsComponent"
                  title="Você tem certeza que deseja excluir essa notícia?"
                  confirmButtonText="Sim"
                  cancelButtonText="Não"
                  @confirm="remove(selectedNewsId)"
                />
              </div>
              <NewEdit
                ref="projectNewsEditComponent"
                :projectId="project.id"
                @close="newEditCloseCallback"
                @save="newEditCloseOnSaveCallback"
              />
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script type="plain/text">
import AucAlert from "@/components/AucAlert.vue";
import FileImage from "@/components/FileImage.vue";
import Modal from "@/components/Modal.vue";
import NewEdit from "@/views/app/project/modal/NewEdit.vue";
import ProjectService from "@/scripts/services/project.service";
import ProjectNews from "@/scripts/models/projectNews.model";

export default {
  components: {
    Modal,
    NewEdit,
    AucAlert,
    FileImage
  },
  props: ["allowEditing"],
  data: () => {
    return {
      project: null,
      dialog: false,
      creatingNewNews: true,
      projectService: new ProjectService(),
      projectNews: new ProjectNews(),
      selectedNewsId: null,
      showModalEdit: false
    };
  },
  methods: {
    open(selectedProject) {
      this.project = selectedProject;
      this.dialog = true;
    },
    openConfirmDeleteNewsModal(selectedNews) {
      this.selectedNewsId = selectedNews;
      this.$refs.deleteNewsComponent.open();
    },
    editNews(news) {
      this.creatingNewNews = false;
      this.$refs.projectNewsEditComponent.open(news, this.creatingNewNews);
      this.dialog = !this.dialog;
    },
    newNews() {
      let projectNews = new ProjectNews();
      this.creatingNewNews = true;
      this.$refs.projectNewsEditComponent.open(
        projectNews,
        this.creatingNewNews
      );
      this.dialog = !this.dialog;
    },
    getImage() {
      if (
        this.projectNews == null ||
        this.projectNews.file == null ||
        !this.projectNews.file.hasOwnProperty("imageContent")
      )
        return "";
      return "url(" + this.projectNews.file.imageContent + ")";
    },
    listByProjectId() {
      this.projectService.listNewsByProjectId(
        this.listByProjectIdCallback,
        this.project.id
      );
    },
    listByProjectIdCallback(data) {
      this.project.newsList = data;
    },
    newEditCloseCallback() {
      this.dialog = !this.dialog;
    },
    newEditCloseOnSaveCallback() {
      this.dialog = !this.dialog;
      this.listByProjectId();
    },
    remove(selectedNewsId) {
      this.projectService.deleteNews(this.removeCallback, selectedNewsId);
    },
    removeCallback() {
      this.listByProjectId();
    }
  }
};
</script>
