<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="730">
      <template></template>
      <v-card>
        <v-card-title class="headline">
          <h1>Clonar</h1>
          <a href="#"></a>
          <v-btn class="close-modal" @click="close">
            <i class="fal fa-times"></i>
          </v-btn>
        </v-card-title>
        <div class="pa-3">
          <v-layout row wrap w-100>
            <template v-for="item in Object.keys(originalModel)">
              <v-checkbox
                color="#02ACB4"
                class="ma-0"
                ref="input"
                v-if="typeof originalModel[item] === 'boolean'"
                v-model="originalModel[item]"
                :label="cloneList[item]"
                :key="item"
                :value="originalModel[item]"
              ></v-checkbox>
            </template>
          </v-layout>
        </div>
        <v-card-actions class="pa-0">
          <div class="footer-modal-atividades">
            <v-btn flat round color="white" class="btn-default px-5" large @click="close">Cancelar</v-btn>
            <v-btn flat round color="white" class="btn-primary px-5" large @click="save">Salvar</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script type="plain/text">
export default {
  props: ["cloneList"],
  data: () => {
    return {
      dialog: false,
      originalModel: Object,
      selectList: []
    };
  },
  methods: {
    save() {
      this.dialog = false;
      this.$emit("clone", this.originalModel);
      this.originalModel = Object();
    },
    close() {
      this.dialog = false;
      this.originalModel = Object;
    },
    open(item) {
      //   console.log(item);
      this.originalModel = item;
      this.dialog = true;
    }
  }
};
</script>